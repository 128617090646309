import React from "react";
import { ReactComponent as Check } from "../../svg/check.svg";
import { ReactComponent as Close } from "../../svg/close.svg";
import { ReactComponent as Exclamation } from "../../svg/exclamation.svg";
import { ReactComponent as Help } from "../../svg/help.svg";
import styles from "./Alert.module.css";

export type AlertType = "error" | "info" | "success" | "warning" | "skeleton";

interface Props {
  children?: React.ReactNode;
  onClose?: () => void;
  type?: AlertType;
}

const Alert: React.FC<Props> = ({ children = "\u00A0", onClose = () => {}, type = "warning" }) => {
  const skeletonClasses = type === "skeleton" ? `${styles.Skeleton} skeleton` : "";

  let icon;
  if (type === "warning") {
    icon = <Exclamation />;
  } else if (type === "info") {
    icon = <Help />;
  } else if (type === "error") {
    icon = <Close />;
  } else if (type === "success") {
    icon = <Check />;
  }

  return (
    <div className={`${styles.Alert} ${styles[type]} ${skeletonClasses}`}>
      {type !== "skeleton" ? (
        <span className={styles.Close}>
          <Close onClick={onClose} />
        </span>
      ) : null}
      <span className={styles.Icon}>{icon}</span>
      {children}
    </div>
  );
};

export default Alert;
