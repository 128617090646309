import React from "react";
import colorKeys from "../../../libs/colors";
import styles from "./Spinner.module.css";

const Spinner: React.FC<{}> = () => (
  <div className={styles.Spinner}>
    <div className={styles.Dot} style={{ color: colorKeys.attraction }} />
    <div className={styles.Dot} style={{ color: colorKeys.performance }} />
    <div className={styles.Dot} style={{ color: colorKeys.mustbe }} />
    <div className={styles.Dot} style={{ color: colorKeys.questionnable }} />
    <div className={styles.Dot} style={{ color: colorKeys.indifferent }} />
    <div className={styles.Dot} style={{ color: colorKeys.reverse }} />
  </div>
);

export default Spinner;
