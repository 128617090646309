import React from "react";

type LazyWithPreload = React.LazyExoticComponent<React.ComponentType<any>> & {
  preload: () => Promise<{ default: React.ComponentType<any> }>;
};

export default function lazy(factory: () => Promise<{ default: React.ComponentType<any> }>) {
  const Component = React.lazy(factory) as LazyWithPreload;
  Component.preload = factory;
  return Component;
}
