import React, { createContext, useEffect, useMemo, useState } from "react";
import { cssColors } from "../../libs/colors";

export type Theme = "light" | "dark";

interface Actions {
  setTheme: (theme: Theme) => void;
}

interface Values {
  theme: Theme;
}

interface ThemeCtx {
  actions: Actions;
  values: Values;
}

interface Props {
  children: React.ReactNode;
}

export const ThemeContext = createContext<ThemeCtx>({
  actions: { setTheme: () => undefined },
  values: { theme: "light" },
});

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>("light");

  const value = useMemo(
    () => ({
      actions: { setTheme },
      values: { theme },
    }),
    [theme]
  );

  useEffect(() => {
    document.body.setAttribute(
      "style",
      cssColors(theme).reduce((acc, color) => `${acc} --${color[0]}: ${color[1]};`, "")
    );
  }, [theme]);

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
