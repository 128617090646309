import { Theme } from "../components/providers/ThemeProvider";

const pickrrGreen = " hsla(160, 86%,  37%, 1)";
const pickrrBlue = "  hsla(214, 100%, 58%, 1)";
const pickrrYellow = "hsla(36,  100%, 42%, 1)";
const pickrrPurple = "hsla(300, 27%,  58%, 1)";
const pickrrGrey = "  hsla(0,   0%,   55%, 1)";
const pickrrRed = "   hsla(0,   100%, 48%, 1)";

const lightBackground = "#f8f9fc";
const darkBackground = "#38393c";

const lightCardBackground = "#ffffff";
const darkCardBackground = "#2e2e2e";

const lightText = "rgba(0, 0, 0, 0.85)";
const darkText = "rgba(255, 255, 255, 1)";

const lightTextLight = "rgba(0, 0, 0, 0.6)";
const darkTextLight = "rgba(255, 255, 255, 0.75)";

const lightBorder = "rgba(0, 0, 0, 0.3)";
const darkBorder = "rgba(255, 255, 255, 0.3)";

const lightLighterBorder = "#e4e4e4";
const darkLighterBorder = "#353535";

const lightLightestBorder = "#f0f0f0";
const darkLightestBorder = "#353535";

const lightReverseText = "rgba(255, 255, 255, 0.85)";
const darkReverseText = "rgba(0, 0, 0, 0.85)";

const lightWhite = "#ffffff";
const darkWhite = "#333333";

const lightWarningBackground = "#fff3e0";
const lightWarningBorder = "#ffcc80";
const lightWarningText = "#ac1900";
const darkWarningBackground = "#f57c00";
const darkWarningBorder = "#e65100";
const darkWarningText = "#fff3e0";

const lightErrorBackground = "#ffebee";
const lightErrorBorder = "#ef9a9a";
const lightErrorText = "#7f0000";
const darkErrorBackground = "#d32f2f";
const darkErrorBorder = "#b71c1c";
const darkErrorText = "#ffebee";

const lightInfoBackground = "#e3f2fd";
const lightInfoBorder = "#90caf9";
const lightInfoText = "#002171";
const darkInfoBackground = "#1976d2";
const darkInfoBorder = "#0d47a1";
const darkInfoText = "#e3f2fd";

const lightSuccessBackground = "#e8f5e9";
const lightSuccessBorder = "#a5d6a7";
const lightSuccessText = "#003300";
const darkSuccessBackground = "#388e3c";
const darkSuccessBorder = "#1b5e20";
const darkSuccessText = "#e8f5e9";

const darkBrand = "#004aff";
const brand = "#006dff";
const lightBrand = "#0073ff";

export const darker = {
  attraction: "hsla(160, 86%, 27%, 1)",
  performance: "hsla(214, 100%, 48%, 1)",
  mustbe: "hsla(36, 100%, 32%, 1)",
  questionnable: "hsla(300, 27%, 48%, 1)",
  indifferent: "hsla(0, 0%, 45%, 1)",
  reverse: "hsla(0, 100%, 38%, 1)",
  "—": "hsla(0, 0%, 45%, 1)",
};

export default {
  performance: pickrrBlue,
  attraction: pickrrGreen,
  mustbe: pickrrYellow,
  reverse: pickrrRed,
  questionnable: pickrrPurple,
  indifferent: pickrrGrey,
  "—": pickrrGrey,
};

export const colors = (theme: Theme = "dark") => {
  return {
    darkBrand,
    brand,
    lightBrand,
    background: theme === "dark" ? darkBackground : lightBackground,
    cardBackground: theme === "dark" ? darkCardBackground : lightCardBackground,
    text: theme === "dark" ? darkText : lightText,
    textLight: theme === "dark" ? darkTextLight : lightTextLight,
    reverseText: theme === "dark" ? darkReverseText : lightReverseText,
    border: theme === "dark" ? darkBorder : lightBorder,
    lighterBorder: theme === "dark" ? darkLighterBorder : lightLighterBorder,
    lightestBorder: theme === "dark" ? darkLightestBorder : lightLightestBorder,
    warningBackground: theme === "dark" ? darkWarningBackground : lightWarningBackground,
    warningBorder: theme === "dark" ? darkWarningBorder : lightWarningBorder,
    warningText: theme === "dark" ? darkWarningText : lightWarningText,
    errorBackground: theme === "dark" ? darkErrorBackground : lightErrorBackground,
    errorBorder: theme === "dark" ? darkErrorBorder : lightErrorBorder,
    errorText: theme === "dark" ? darkErrorText : lightErrorText,
    infoBackground: theme === "dark" ? darkInfoBackground : lightInfoBackground,
    infoBorder: theme === "dark" ? darkInfoBorder : lightInfoBorder,
    infoText: theme === "dark" ? darkInfoText : lightInfoText,
    successBackground: theme === "dark" ? darkSuccessBackground : lightSuccessBackground,
    successBorder: theme === "dark" ? darkSuccessBorder : lightSuccessBorder,
    successText: theme === "dark" ? darkSuccessText : lightSuccessText,
    white: theme === "dark" ? darkWhite : lightWhite,
    pickrrGreen,
    pickrrBlue,
    pickrrYellow,
    pickrrPurple,
    pickrrGrey,
    pickrrRed,
  };
};

export const cssColors = (theme: Theme = "dark") => {
  return [
    ["dark-brand", darkBrand],
    ["brand", brand],
    ["light-brand", lightBrand],
    ["background", theme === "dark" ? darkBackground : lightBackground],
    ["card-background", theme === "dark" ? darkCardBackground : lightCardBackground],
    ["text", theme === "dark" ? darkText : lightText],
    ["text-light", theme === "dark" ? darkTextLight : lightTextLight],
    ["reverse-text", theme === "dark" ? darkReverseText : lightReverseText],
    ["border", theme === "dark" ? darkBorder : lightBorder],
    ["lighter-border", theme === "dark" ? darkLighterBorder : lightLighterBorder],
    ["lightest-border", theme === "dark" ? darkLightestBorder : lightLightestBorder],
    ["warning-background", theme === "dark" ? darkWarningBackground : lightWarningBackground],
    ["warning-border", theme === "dark" ? darkWarningBorder : lightWarningBorder],
    ["warning-text", theme === "dark" ? darkWarningText : lightWarningText],
    ["error-background", theme === "dark" ? darkErrorBackground : lightErrorBackground],
    ["error-border", theme === "dark" ? darkErrorBorder : lightErrorBorder],
    ["error-text", theme === "dark" ? darkErrorText : lightErrorText],
    ["info-background", theme === "dark" ? darkInfoBackground : lightInfoBackground],
    ["info-border", theme === "dark" ? darkInfoBorder : lightInfoBorder],
    ["info-text", theme === "dark" ? darkInfoText : lightInfoText],
    ["success-background", theme === "dark" ? darkSuccessBackground : lightSuccessBackground],
    ["success-border", theme === "dark" ? darkSuccessBorder : lightSuccessBorder],
    ["success-text", theme === "dark" ? darkSuccessText : lightSuccessText],
    ["white", theme === "dark" ? darkWhite : lightWhite],
    ["pickrr-green", pickrrGreen],
    ["pickrr-blue", pickrrBlue],
    ["pickrr-yellow", pickrrYellow],
    ["pickrr-purple", pickrrPurple],
    ["pickrr-grey", pickrrGrey],
    ["pickrr-red", pickrrRed],
  ];
};
