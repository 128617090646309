import Company from "./company";

interface UserToken {
  token: string;
}

export default class User {
  constructor(
    public id: string = "",
    public name: string = "",
    public email: string = "",
    public companies: Company[] = []
  ) {}
}
