import cuid from "cuid";
import React, { createContext, useCallback, useMemo, useReducer } from "react";
import { objectOmmit } from "../../libs/objects";

export type NotificationType = "error" | "info" | "success" | "warning";

interface Props {
  children: React.ReactNode;
}

interface Notification {
  type: NotificationType;
  text: string;
}

interface State {
  [key: string]: Notification;
}

interface Action {
  type: string;
  id: string;
  value?: Notification;
}

interface Actions {
  addNotification: (notification: Notification) => void;
  removeNotification: (id: string) => void;
  addError: (text: string) => void;
  addInfo: (text: string) => void;
  addSuccess: (text: string) => void;
  addWarning: (text: string) => void;
}

interface Values {
  notifications: State;
}

interface NotificationCtx {
  actions: Actions;
  values: Values;
}

export const NotificationContext = createContext<NotificationCtx>({
  actions: {
    addNotification: () => undefined,
    removeNotification: () => undefined,
    addError: () => undefined,
    addInfo: () => undefined,
    addSuccess: () => undefined,
    addWarning: () => undefined,
  },
  values: {
    notifications: {},
  },
});

const initialState = {};

function reducer(state: State, action: Action): State {
  if (action.type === "add" && action.value) {
    return { ...state, [action.id]: action.value };
  }

  if (action.type === "remove") {
    return objectOmmit(state, action.id);
  }

  return state;
}

const NotificationProvider: React.FC<Props> = ({ children }) => {
  const [notifications, dispatch] = useReducer(reducer, initialState);

  /* eslint-disable react-hooks/exhaustive-deps */
  const removeNotification = React.useCallback((id) => {
    dispatch({ type: "remove", id });
  }, []);

  const addNotification = React.useCallback(
    (notification) => {
      const id = cuid();

      dispatch({ type: "add", id, value: notification });
      setTimeout(removeNotification, 5000, id);
    },
    [removeNotification]
  );

  const addError = useCallback((text) => addNotification({ type: "error", text }), []);
  const addInfo = useCallback((text) => addNotification({ type: "info", text }), []);
  const addSuccess = useCallback((text) => addNotification({ type: "success", text }), []);
  const addWarning = useCallback((text) => addNotification({ type: "warning", text }), []);

  const value = useMemo(
    () => ({
      actions: { addNotification, removeNotification, addError, addInfo, addSuccess, addWarning },
      values: { notifications },
    }),
    [addNotification, removeNotification, notifications]
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
