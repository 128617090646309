import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import App from "./App/App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    debug: true,

    fallbackLng: process.env.NODE_ENV === "development" ? "dev" : "fr",
    whitelist: process.env.NODE_ENV === "development" ? ["dev", "en", "fr"] : ["fr"],
    ns: ["campaign", "help", "kano", "modal", "page", "user"],

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      addPath: "/locales/add/{{lng}}/{{ns}}",
    },

    saveMissing: true,
    saveMissingTo: "all",
  });

i18n.on("languageChanged", (lng) => {
  dayjs.locale(lng);
});

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
