export default true;
export const objectOmmit = (state: any, id: string) => {
  return Object.keys(state).reduce(
    (acc, value) => ({
      ...acc,
      ...(value !== id ? { [value]: state[value] } : {}),
    }),
    {}
  );
};
