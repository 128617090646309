import PropTypes from "prop-types";
import React from "react";
import NotificationCenter from "../../components/organisms/NotificationCenter";
import ApolloProvider from "../../components/providers/ApolloProvider";
import NotificationProvider from "../../components/providers/NotificationProvider";
import ThemeProvider from "../../components/providers/ThemeProvider";
import UserProvider from "../../components/providers/UserProvider";

interface Props {
  children: React.ReactNode;
}

const Providers: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider>
      <ApolloProvider>
        <UserProvider>
          <NotificationProvider>
            <NotificationCenter />
            {children}
          </NotificationProvider>
        </UserProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;
